<template>
  <div class="support">
    <PageHeader :header="headData" />
    <v-container fluid class="grey lighten-5 text--secondary text-center">
      <h2 class="my-10 text-h3 green--text">会員募集</h2>
      <p>私たちの活動に賛同し、会員になってくださる方を募っております。</p>
      <v-row justify="center" align="center" class="mt-6">
        <v-col cols="12" sm="6" md="4">
          <v-card class="grey lighten-4 text-center rounded pa-8" elevation="0">
            <h3 class="mb-6 text-h4 text--secondary">個人</h3>
            <v-row class="">
              <v-col tag="dl">
                <dt>入会金</dt>
                <dd class="text-h5">1,000円</dd>
              </v-col>
              <v-col tag="dl">
                <dt>年会費</dt>
                <dd class="text-h5">1,000円</dd>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="grey lighten-4 text-center rounded pa-8" elevation="0">
            <h3 class="mb-6 text-h4 text--secondary">団体</h3>
            <v-row class="">
              <v-col tag="dl">
                <dt>入会金</dt>
                <dd class="text-h5">10,000円</dd>
              </v-col>
              <v-col tag="dl">
                <dt>年会費</dt>
                <dd class="text-h5">10,000円</dd>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="grey lighten-5 text--secondary">
      <div class="text-center mb-6">
        <h2 class="my-10 text-h3 green--text">ご寄付</h2>
        <p>皆さんのお気持ちがこども達の未来につながります。</p>
      </div>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="9" md="6">
          <div class="skill-wrapper">
            <dl class="define-row">
              <dt class="define-item">横浜銀行藤沢中央支店</dt>
              <dd class="define-item">普通 6067023</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">中央労働金庫藤沢支店</dt>
              <dd class="define-item">普通 3350294</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">郵便振替 口座番号</dt>
              <dd class="define-item">00200-3-54726</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">口座名</dt>
              <dd class="define-item">NPO法人 こども達に未来を in 湘南</dd>
            </dl>
            <dl class="define-row">
              <dt class="define-item">代表者</dt>
              <dd class="define-item">松延 康隆</dd>
            </dl>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
export default {
  name: "Support",
  components: { PageHeader },
  data: () => ({
    headData: {
      title: "ご支援のお願い",
      img: "/assets/pic/header/support-header_mini.jpg"
    }
  })
};
</script>
